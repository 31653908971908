<template>
  <section
    class="ct-richtext-with ct-richtext-with--media"
    :class="{ 'ct-richtext-with--mirrored': slice?.RichtextToTheRight }"
  >
    <div class="ct-richtext-with__container container">
      <div class="content-type__content">
        <div
          v-if="slice?.Settings?.Title"
          class="content-type__heading heading heading--2"
        >
          {{ slice.Settings.Title }}
        </div>

        <p
          v-if="slice?.Settings?.Description"
          class="content-type__description"
        >
          {{ slice.Settings.Description }}
        </p>

        <div class="ct-richtext-with__grid">
          <div
            v-if="slice?.Richtext?.length"
            class="ct-richtext-with__richtext"
            :style="`color: ${slice.Color || 'inherit'}`"
          >
            <StrapiBlocksText
              :nodes="slice.Richtext"
            />
          </div>

          <component
            :is="getElement(slice?.File?.Link)"
            v-if="slice?.File?.Media?.url && slice?.File?.Media?.mime?.includes('image')"
            class="ct-richtext-with__image"
            :to="slice.File.Link"
          >
            <img
              :src="getProductImage(slice.File.Media.url, 'f=rs:fit:642')"
              :alt="`${slice.File.Media?.alternativeText || slice.File.Media?.caption || ''}`"
              :width="slice.File.Media.width"
              :height="slice.File.Media.height"
              :loading="index ? 'lazy' : 'eager'"
              :fetchpriority="index ? 'low' : 'high'"
              :decoding="index ? 'async' : 'sync'"
            >
          </component>

          <UiVideo
            v-if="slice?.File?.Media?.url && slice?.File?.Media?.mime?.includes('video')"
            class="ct-richtext-with__video"
            :src="slice?.File?.Media?.url"
            :alt="`${slice.File.Media?.alternativeText || slice.File.Media?.caption || ''}`"
            :width="slice.File.Media.width"
            :height="slice.File.Media.height"
            :loading="index ? 'lazy' : 'eager'"
            :controls="false"
            autoplay
            muted
            loop
          />
        </div>
      </div>
    </div>

    <!--  -->
  </section>
</template>

<script lang="ts" setup>
import { AppLink } from '#components'

defineProps({
  slice: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  slices: {
    type: Array,
    required: true,
  },
  context: {
    type: [Object, Array],
    default: () => null,
  },
})

function getElement(to: any) {
  return to ? AppLink : 'div'
}
</script>

<style src="~layers/app/components/Slices/RichtextWith.css" />

<style src="~layers/app/components/Slices/RichtextWithMedia.css" />
